import React, {useState, useEffect} from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import JobslistContainer from '../components/Career/JobslistContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import {useLocation} from "react-router-dom";
import data3 from "../data/SEO/seoData";
import axios from "axios";
import jobsData from "../data/career/jobsData";

const Career = () => {
    console.log("logs from page career");
    const baseUri = "https://tetrahed.s3.us-east-1.amazonaws.com/jobsData.json";
    const fetchDataFromAPI = async () => {
        try {
            const response = await axios.get(baseUri);
            if (response.status === 200) {
                console.log(response)
                setFetchedData(response.data);
            } else {
                throw new Error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
            // setting internal JobsData
            setFetchedData(jobsData);
        }
    };
    const [fetchedData, setFetchedData] = useState([]);
    useEffect(() => {
        fetchDataFromAPI().then();
    }, []);

// Render job details only if fetchedData is available
    /*const jobData = fetchedData ? fetchedData.find(job => job.id === jobId) : null;*/
    const currentPage = useLocation();
    let seo_data = data3.filter(dataObj => dataObj.path === currentPage.pathname);
    return (
        <React.Fragment>
            <SEO title={seo_data[0].metaTitle} URL={seo_data[0].path} description={seo_data[0].metaDesc} />
            <Header />
            <Breadcrumb
                image={seo_data[0].bcEleImage}
                title={seo_data[0].bcEleTitle}
                content="Home"
                contentTwo="careers"
            />
            <JobslistContainer data={fetchedData} />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Career;



