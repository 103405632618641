import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import Parallax from 'parallax-js';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import Joblist from "./Joblist";
import {Link} from "react-router-dom";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en';
import ReactPaginate from 'react-paginate';

const JobslistContainer = ({ classOption, data }) => {
    console.log("Data logged from joblistContainer", data);
    const jobsData = data;
    TimeAgo.addDefaultLocale(en);
    /*TimeAgo.addLocale(en);*/
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, []);

    // Pagination settings
    const blogsPerPage = 14;
    const [currentPage, setCurrentPage] = useState(0); // react-paginate uses 0-based indexing

    // Get current jobs
    const indexOfLastBlog = (currentPage + 1) * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentJobs = jobsData.slice(indexOfFirstBlog, indexOfLastBlog);

    // Pagination handler
    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    // Calculate total page count
    const pageCount = Math.ceil(jobsData.length / blogsPerPage);

    return (
        <div className={`section section-padding-t90 section-padding-botto pb-15 ${classOption}`}>
            <div className="container">
                <SectionTitle
                    headingOption="fz-32"
                    title="Recent Open Positions"
                    subTitle="Explore new career opportunities with us and join on the path to success"
                />

                <div className="row row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 mb-n6 icon-box-shape-animation">
                    {currentJobs && currentJobs.map((single, key) => (
                        <div key={key} className="col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300">
                            <Joblist classOption="box-border" data={single} />
                        </div>
                    ))}

                    <div className="shape shape-1" id="scene" ref={sceneEl}>
                        <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mt-10">
                        <ReactPaginate
                            previousLabel={<i className="fas fa-chevron-left"></i>}
                            nextLabel={<i className="fas fa-chevron-right"></i>}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobslistContainer;
